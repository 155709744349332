import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Img from '../components/AtomImg'
import Div from './../components/Div'
import Column from '../components/Column'
import Paragraph from '../components/Paragraph'
import H4 from '../components/H4'
import H6 from '../components/H6'
import Badge from '../images/badge-24-px@3x.svg'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledCulture,
  headignContent,
  highlightBox,
  jobRole,
  jobItemBps,
  dividerBorder
} from '../components/styles/Culture.styles'

export const PageTemplate = ({
  data
}) => {
  return (
    <section css={styledCulture}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
        >
          <Column
            width={[1]}
          >
            <Div
              maxWidth={['90%', '70%', 400, 450]}
              style={{
                margin: '0 auto'
              }}
            >
              <Img
                fluid={data.image1.childImageSharp.fluid}
                loading='eager'
              />
            </Div>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'row']}
          css={headignContent}
          mt={20}
          mb={50}
        >
          <Column
            width={[1]}
          >
            <Paragraph>
              Não somos apaixonados apenas por tecnologia, somos apaixonados por desenvolver as pessoas. Por isso, carregamos em nosso DNA valores que unem essas duas paixões: integridade, inovação, disponibilidade e parceria. Prezamos por um ambiente que proporcione o desenvolvimento humano e intelectual de nossos colaboradores, pois acreditamos que a tecnologia tem o poder de transformar a vida das pessoas.
            </Paragraph>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'row']}
        >
          <Column
            width={[1, 1, 1, 1]}
          >
            <div css={highlightBox}>
              <img src={Badge} />
              <H4>Selo Great Place to Work</H4>
              <div css={dividerBorder} />
              <Paragraph>O Programa de Certificação GPTW permite a medição da percepção dos funcionários em relação à empresa e seu clima organizacional. As organizações melhor avaliadas são ranqueadas e recebem um selo de certificação de ser uma das Melhores Empresas para se Trabalhar. Conquistar este selo em nossa primeira participação no programa, nos assegura que estamos entre as melhores empresas também para nossos colaboradores.</Paragraph>
            </div>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={jobRole}
          pt={[50, 100]}
          pb={[50, 100]}
        >
          <Column
            width={[1, 1, 4/12, 4/12]}
          >
            <div>
              <H6>Luis Henrique Atroch</H6>
              <span>Analista de Redes</span>
              <Paragraph>"Aqui somos desafiados a cada dia para sermos melhores, vencer obstáculos, buscar sonhos e ter coragem para enfrentar qualquer batalha! Além de acreditar na cultura e nos valores da empresa, tenho muita admiração e orgulho das pessoas que trabalham comigo."</Paragraph>
            </div>
          </Column>
          <Column
            width={[1, 1, 4/12, 4/12]}
          >
            <div css={jobItemBps}>
              <H6>Lucas Montarroios</H6>
              <span>Gerente de Data Center</span>
              <Paragraph>"Trabalhar na hostdime é fazer parte da inovação de grandes empresas diariamente, sem sair de casa. É assim que eu me sinto, em casa.”</Paragraph>
            </div>
          </Column>
          <Column
            width={[1, 1, 4/12, 4/12]}
          >
            <H6>Flávia Tabosa</H6>
            <span>Coordenadora de Marketing</span>
            <Paragraph>"Aqui é um espaço fértil para oportunidades, consigo ser forte na minha especialidade ao mesmo tempo que tenho a liberdade de mergulhar em novos desafios e desafiar fronteiras. Na HDBR posso, constantemente, melhorar como profissional e pessoa”</Paragraph>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Cultura da inovação | HostDime Brasil' 
        description='Carregamos em nosso DNA valores que unem a tecnologia e o desenvolvimento das pessoas: integridade, inovação, disponibilidade e parceria.' 
      />
      <PageTemplate
        title='Cultura'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cultura/cultura.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
